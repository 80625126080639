import { generateColor } from "../helpers/PhaserHelpers";
import DrumMachine from "./drumMachine";
import PatternManager from "./patternManager";
import BassPatternPad from "./bassPatternPad";
import BassPlayer from "./bassPlayer";


export default class BassPatternManager extends PatternManager {

    helpText: Phaser.GameObjects.Text;
    pads: BassPatternPad[] = [];
    resetBtn: Phaser.GameObjects.Ellipse;
    size: string;
    bassPlayer: BassPlayer;
    bg1: Phaser.GameObjects.Rectangle;
    bg: Phaser.GameObjects.Rectangle;


    constructor(scene: Phaser.Scene, x: number, y: number, drumMachine?: BassPlayer, helpText?: Phaser.GameObjects.Text, size: string = '50') {
        super(scene, x, y);
        this.helpText = helpText
        this.bassPlayer = drumMachine
        this.size = size
        
        this.addPads();
      
        this.scene.add.existing(this);
    }
    getPads(){
        let pds = []
        this.pads.forEach((eachPad)=>{
            pds.push(eachPad.getPad())
        })
        return pds
    }

    addPads() {
        this.bg = this.scene.add.rectangle(this.x - 38, this.y - 5, 705, 35, generateColor(), .75).setOrigin(0).setDepth(2)
        .setStrokeStyle(1, 0xc1c1c1, .75)
        this.resetBtn = this.scene.add.ellipse(this.x - 27, this.y + 5, 15,15, generateColor(), 1).setOrigin(0).setDepth(3)
        .setInteractive({useHandCursor: true})
        .on("pointerdown", () => {
            this.pads.forEach((eachPad)=>{
                eachPad.clearPattern();
            })

        }).setStrokeStyle(2, 0x00000, .5)
        .on('pointerover', () => {
            this.helpText.setText("Reset all Saved Bass parts")
        })
        .on('pointerout', () => {
            this.helpText.setText("")
        })
        
        let hSpace = 0
        for (var i = 0; i < 22; i++) {
            this.pads.push(new BassPatternPad(this.scene, this.x + hSpace, this.y, this.bassPlayer, this.helpText, null, i));
            hSpace += 30
        }

    }
}
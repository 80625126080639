const starterLopps = {
    "Rock Beat 2": [
        { "seqData": [true, false, false, false, false, false, true, false, true, false, true, false, false, false, true, false] }, { "seqData": [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Rock Beat 1": [
        { "seqData": [true, false, false, false, false, false, false, false, true, false, true, false, false, false, false, false] }, { "seqData": [false, false, true, false, false, false, true, false, false, false, true, false, false, false, true, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Basic Roachclip": [
        { "seqData": [true, false, true, false, false, false, true, false, false, true, true, false, false, false, false, false] }, { "seqData": [true, false, true, false, true, false, true, false, true, false, false, false, true, false, true, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, false, true, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Amen Brother": [
        { "seqData": [true, false, true, false, false, false, false, false, false, false, false, true, true, false, false, false] }, { "seqData": [true, false, true, false, true, false, true, false, true, false, true, false, true, false, true, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, true, false, true, false, false, true, false, false, true] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Basic Hip Hop": [
        { "seqData": [true, false, false, false, false, false, false, true, false, false, true, false, false, false, true, false] }, { "seqData": [true, false, true, false, true, false, true, false, true, false, true, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, true, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Kendrick Lamar": [
        { "seqData": [true, false, false, true, false, false, true, false, false, false, true, false, false, true, false, false] }, { "seqData": [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Funky President": [
        { "seqData": [true, false, false, true, false, false, false, true, false, true, true, false, false, false, false, false] }, { "seqData": [true, false, true, false, true, false, true, false, true, false, false, false, true, false, true, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, true, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "Boom Bap": [
        { "seqData": [true, false, false, false, true, false, false, false, true, false, false, false, true, false, false, false] }, { "seqData": [true, false, true, false, true, false, true, true, true, false, true, false, true, false, true, true] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, true, false, false, false, false, false, false, false, true, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }, { "seqData": [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false] }],
    "The Funky Drummer": [
        {
            "seqData": [
                true,
                false,
                true,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                true,
                false,
                false,
                true,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                false,
                true,
                true,
                true,
                true,
                true,
                false,
                true,
                true
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                true,
                false,
                true,
                false,
                true,
                true,
                false,
                false,
                true
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ],
    "Billie Jean": [
        {
            "seqData": [
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ],
    "Ashleys Roachclip": [
        {
            "seqData": [
                true,
                false,
                true,
                false,
                false,
                false,
                true,
                false,
                false,
                true,
                true,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                false,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ],
    "When the Levee Breaks": [
        {
            "seqData": [
                true,
                true,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                true,
                true,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ],
    "Impeach the President": [
        {
            "seqData": [
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                true,
                false,
                false,
                false,
                false,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                true,
                true,
                false,
                false,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ],
    "Walk this Way": [
        {
            "seqData": [
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                true,
                false,
                true,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false,
                true,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        },
        {
            "seqData": [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false
            ]
        }
    ]
}

export default starterLopps
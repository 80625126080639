import { Loop, Transport } from "tone";
import { theWindow } from "tone/build/esm/core/context/AudioContext";
import { HighlightSpanKind } from "typescript";
import { generateColor } from "../helpers/PhaserHelpers";
import BassPatternManager from "./bassPatternManager";
import BassPlayer from "./bassPlayer";
import DrumMachine from "./drumMachine";
import PatternManager from "./patternManager";

class myRect extends Phaser.GameObjects.Rectangle {
    public pattern: string
    public patternLabel: string
    public setLabel?: (l: string) => void
}
export default class Looper extends Phaser.GameObjects.Container {

    bg: Phaser.GameObjects.Rectangle;
    drumSeqs: any = [];
    drumSeqIndex: number = 0
    zones_drums: myRect[] = [];
    zones_bass: myRect[] = [];
    bg2: Phaser.GameObjects.Rectangle;
    bassPatterns: BassPatternManager;
    drumPatterns: PatternManager;
    labelText: Phaser.GameObjects.Text[] = [];
    resetBassPatterns: Phaser.GameObjects.Ellipse;
    helpText: Phaser.GameObjects.Text;
    dragging: boolean;
    pattern: string;
    i: number;
    drumMachine: DrumMachine;
    bassPlayer: BassPlayer;
    patternLength: number = 14
    mainSequences: any[] = [];
    loop: Loop<any>;
    setPatterns: Phaser.GameObjects.Ellipse;
    mainSeqActivated: boolean = false;
    bassSeqs: any[] = [];
    bassSeqIndex: number = 0;
    loopModeIndicator: Phaser.GameObjects.Rectangle;

    constructor(scene: Phaser.Scene, x: number, y: number, bassPatterns: BassPatternManager, drumPatterns: PatternManager, drumMachine: DrumMachine, bassPlayer: BassPlayer, helpText?: Phaser.GameObjects.Text) {
        super(scene, x, y)
        this.helpText = helpText
        Transport.on('start', () => {
            if(this.loop){
                this.loop.start(0)
            }
            
        })
        Transport.on('stop', () => {
            this.zones_bass.forEach((eachBassPad) => {
                this.unHighlightPad(eachBassPad)
            })
            this.zones_drums.forEach((eachDrumPad) => {
                this.unHighlightPad(eachDrumPad)
            })
            this.i = 0
            this.drumSeqIndex = 0
            this.bassSeqIndex = 0
            if (this.loop) {
                this.loop.set({ position: '00:00:00' })
            }

        })
        this.drumMachine = drumMachine
        this.bassPlayer = bassPlayer
        this.bassPatterns = bassPatterns
        this.drumPatterns = drumPatterns
        this.makeBackground();
        this.makeControlSurface();
        this.makeSetLoopButtons();
        this.scene.add.existing(this);
    }
    getPatterns() {
        let t = []
        this.zones_bass.forEach((eachPattern) => {
            t.push(eachPattern)
        })
        return t;
    }
    makeMainPattern() {
        this.loop = new Loop((time: number) => {
            if (this.mainSeqActivated) {

                this.drumMachine.loadMainSeqLoop(this.drumSeqs[this.drumSeqIndex])


                this.bassPlayer.loadGeneratedLoop(this.bassSeqs[this.bassSeqIndex])


                // remove highights
                this.zones_bass.forEach((eachBassPad) => {
                    this.unHighlightPad(eachBassPad)
                })
                this.zones_drums.forEach((eachDrumPad) => {
                    this.unHighlightPad(eachDrumPad)
                })
                // highlight current measure
                this.highlightPad(this.zones_bass[this.bassSeqIndex])
                this.highlightPad(this.zones_drums[this.drumSeqIndex])
                this.drumSeqIndex++
                this.bassSeqIndex++
                if (this.drumSeqIndex == this.drumSeqs.length) {
                    this.drumSeqIndex = 0
                }
                if (this.bassSeqIndex == this.bassSeqs.length) {
                    this.bassSeqIndex = 0
                }
            }

        }, `1m`).start(0)
    }
    clearPatterns() {
        this.zones_bass.forEach((eachPattern) => {
            eachPattern.destroy()
        })
        this.zones_drums.forEach((eachPattern) => {
            eachPattern.destroy()
        })
        this.removeLabel()
        this.makeControlSurface()
    }
    addLabel(obj: any, i: number) {
        console.log(i)
        if(this.labelText[i]){
            this.labelText[i].setText(obj.patternLabel)
        } 
    }
    removeLabel() {
        Object.keys(this.labelText).forEach((eachKey) => {
            this.labelText[eachKey].setText('')
        }) //  = this.scene.add.text(obj.x + 3,obj.y + 2, obj.patternLabel, { fontSize: '12px', color: '#000000'} ).setOrigin(0).setDepth(3)

    }
    loadSequences() {
        let check: boolean = true
        this.zones_bass.forEach((eachBassPattern) => {

            if (localStorage.getItem(eachBassPattern.pattern)) {
                let parts = JSON.parse(localStorage.getItem(eachBassPattern.pattern))
                console.log(parts)
                this.bassSeqs.push(parts)
            }
        })
        this.zones_drums.forEach((eachBassPattern) => {

            if (localStorage.getItem(eachBassPattern.pattern)) {
                let parts = JSON.parse(localStorage.getItem(eachBassPattern.pattern))
                console.log(parts)
                this.drumSeqs.push(parts)
            }

        })

        this.makeMainPattern()
    }
    makeBackground() {
        this.bg = this.scene.add.rectangle(this.x, this.y, 690, 55, generateColor(), 1)
            .setOrigin(0).setDepth(3).setStrokeStyle(1, 0xc1c1c1, .75)
        this.bg2 = this.scene.add.rectangle(this.x, this.y + 60, 690, 30, generateColor(), 1)
            .setOrigin(0).setDepth(3).setStrokeStyle(1, 0xc1c1c1, .75)
    }
    highlightPad(pad: Phaser.GameObjects.Rectangle) {
        pad.setFillStyle(0xffffff, 1)
    }
    unHighlightPad(pad: Phaser.GameObjects.Rectangle) {
        pad.setFillStyle(0xc1c1c1, .5)
    }
    makeSetLoopButtons() {
        this.setPatterns = this.scene.add.ellipse(this.x + 5, this.y + 5, 20, 20, generateColor(), 1).setOrigin(0).setDepth(3)
            .setInteractive({ useHandCursor: true }).setStrokeStyle(1, 0x000000, .75)
            .on('pointerdown', () => {
                if (Transport.state === 'stopped') {
                    if (!this.mainSeqActivated) {
                        this.loopModeIndicator = this.scene.add.rectangle(this.x - 10, this.y - 5, this.bg.width + 20, this.bg.height + 45, generateColor(), .0)
                            .setStrokeStyle(2, 0xff00000, .5).setOrigin(0).setDepth(3)
                        this.mainSeqActivated = true
                        this.bassSeqIndex = 0
                        this.drumSeqIndex = 0
                        this.loadSequences();

                        Transport.loop = true
                        this.helpText.setText("LOOPING MODE")
                        Transport.set({ loopStart: '0:0:0', loopEnd: '13:0:0' })


                    } else {
                        this.mainSeqActivated = false
                        Transport.loop = false
                        this.helpText.setText("NORMAL MODE")
                        this.loopModeIndicator.destroy();
                    }
                } else {
                    this.helpText.setText('Not while the Transport is running')
                }


            })
            .on('pointerover', () => {

                this.helpText.setText('Loop Main Sequence')

            })
            .on('pointerout', () => {
                this.helpText.setText('')
            })


        this.resetBassPatterns = this.scene.add.ellipse(this.x + 10, this.y + 30, 10, 10, generateColor(), 1).setOrigin(0).setDepth(3)
            .setInteractive({ useHandCursor: true }).setStrokeStyle(1, 0x000000, .75)
            .on('pointerdown', () => {
                if (Transport.state === 'stopped') {
                    this.clearPatterns()
                } else {
                    this.helpText.setText('Not while the Transport is running')
                }

            })
            .on('pointerover', () => {
                this.helpText.setText('Reset All Sequences')

            })
            .on('pointerout', () => {
                this.helpText.setText('')
            })

    }
    makeControlSurface() {

        let xSpace = 35
        for (var i = 0; i < 13; i++) {
            let t = this.scene.add.rectangle(this.x + xSpace, this.y + 5, 45, 45, 0xc1c1c1, .5)
                .setOrigin(0).setDepth(3).setInteractive()
                .setStrokeStyle(1, 0x000000, 1)
                .on('pointerover', () => {
                    console.log('into pointerover')
                    console.log(sessionStorage.getItem('currentPattern'))
                    let pointer = this.scene.input.activePointer;
                    if (pointer.isDown && sessionStorage.getItem('currentPattern')) {
                        let patternCheck = sessionStorage.getItem('currentPattern').split("_")[0] === 'ORIGINAL'
                        console.log(patternCheck)
                        if (patternCheck) {
                            this.labelText[i] = this.scene.add.text(t.x + 3, t.y + 2, t.patternLabel, { fontSize: '12px', color: '#000000' }).setOrigin(0).setDepth(3)

                            t.pattern = sessionStorage.getItem('currentPattern')
                            t.patternLabel = sessionStorage.getItem('currentPatternLabel')
                            console.log(t)
                            this.addLabel(t, i)
                            t.setStrokeStyle(3, 0x000000, 1)
                        }
                    }

                }) as myRect
            // .on('pointerout', () => {

            //     sessionStorage.removeItem('currentPattern')
            //     sessionStorage.removeItem('currentPatternLabel')
            //     t.setStrokeStyle(1, 0x000000, 1)
            // }) as myRect
            this.zones_drums.push(t)
            xSpace += 50
        }

        let xSpace2 = 35
        for (var i = 0; i < 13; i++) {
            let t2 = this.scene.add.rectangle(this.x + xSpace2, this.y + 65, 45, 20, 0xc1c1c1, .5).setOrigin(0).setDepth(3)
                .setStrokeStyle(1, 0x000000, 1).setInteractive()
                .on('pointerover', () => {

                    let pointer = this.scene.input.activePointer;

                    if (pointer.isDown && sessionStorage.getItem('currentPattern')) {

                        console.log(sessionStorage.getItem('currentPattern'))
                        let patternCheck = sessionStorage.getItem('currentPattern').split("_")[0] === 'BASS'
                        console.log(patternCheck)
                        if (patternCheck) {
                            t2.pattern = sessionStorage.getItem('currentPattern')
                            t2.patternLabel = sessionStorage.getItem('currentPatternLabel')
                            console.log(t2)
                            this.labelText[i] = this.scene.add.text(t2.x + 3, t2.y + 2, t2.patternLabel, { fontSize: '12px', color: '#000000' }).setOrigin(0).setDepth(3)

                            t2.setStrokeStyle(3, 0x000000, 1)
                        }

                    }

                }) as myRect
        
            this.scene.physics.add.existing(t2)

            this.zones_bass.push(t2)
            xSpace2 += 50
        }


    }

    update() {

    }
}